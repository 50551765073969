import {LOCALE_CHANGE, SET_LOCALE_LIST} from "./localeActionTypes";

export const changeLocale = (locale: any) => ({
    type: LOCALE_CHANGE,
    payload: locale,
});

export const setLocaleList = (locales: any) => {
    return {
        type: SET_LOCALE_LIST,
        payload: locales,
    };
};
