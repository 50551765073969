import {
    LOGO_URL,
    MENU_LIST,
    SET_SOCIAL,
    SET_DOMAIN,
    SET_POPUP,
    INIT_STATE,
    SET_B_ORDER,
    SET_FB_PIXEL,
    SET_CUSTOM_JS,
    SET_CURRENCIES,
    CATEGORIS_DATA,
    SET_SLIDE_IMAGES,
    SET_CASH_VERSION,
    SET_TRANSLATIONS,
    SET_STORE_CONFIGS,
    SET_INITIAL_MAX_PRICE,
    SET_INITIAL_MIN_PRICE,
    SET_POPUP_NAME,
    SET_CONFIGS,
    SET_UP_CROSS_PRODS,
    SET_TEMPORARY_DATA,
    SET_DB_NAME,
    SET_META_TAGS,
} from "./generalActionTypes";

export const setInitialMinPrice = (payload: any) => {
    return {
        type: SET_INITIAL_MIN_PRICE,
        payload,
    };
};

export const setMetaPath = (payload: any) => {
    return {
        type: SET_DB_NAME,
        payload,
    };
};
export const setMetaTags = (payload: any) => {
    return {
        type: SET_META_TAGS,
        payload,
    };
};

export const setInitialMaxPrice = (payload: any) => {
    return {
        type: SET_INITIAL_MAX_PRICE,
        payload,
    };
};

export function setDomain(payload: any) {
    return (dispatch: (arg0: { type: string; payload: any; }) => any) =>
        dispatch({
            type: SET_DOMAIN,
            payload,
        });
}

export function setTranslations(payload: any) {
    return (dispatch: (arg0: { type: string; payload: any; }) => any) =>
        dispatch({
            type: SET_TRANSLATIONS,
            payload,
        });
}

export function setCurrencies(payload: any) {
    return (dispatch: (arg0: { type: any; payload: any; }) => any) =>
        dispatch({
            type: SET_CURRENCIES,
            payload,
        });
}

export function setBackorders(backorders: any) {
    return (dispatch: (arg0: { type: string; payload: any; }) => any) =>
        dispatch({
            type: SET_B_ORDER,
            payload: backorders,
        });
}

export function setCustomJs(customJs: any) {
    return (dispatch: (arg0: { type: string; payload: any; }) => any) =>
        dispatch({
            type: SET_CUSTOM_JS,
            payload: customJs,
        });
}

export function setSlideImages(array: any) {
    return (dispatch: (arg0: { type: string; payload: any; }) => any) =>
        dispatch({
            type: SET_SLIDE_IMAGES,
            payload: array,
        });
}

export function initState() {
    return (dispatch: (arg0: { type: string; }) => any) =>
        dispatch({
            type: INIT_STATE,
        });
}

export function setMenuList(payload: any) {
    return (dispatch: (arg0: { type: string; payload: any; }) => any) =>
        dispatch({
            type: MENU_LIST,
            payload,
        });
}

export function setLogo({payload}: { payload: any }) {
    return (dispatch: (arg0: { type: string; payload: any; }) => any) =>
        dispatch({
            type: LOGO_URL,
            payload,
        });
}

export const setCrossValid = (payload: boolean) => ({
    type: "SET_VALID_CROSS",
    payload,
});


export const setUpCrossProd = (payload: boolean) => ({
    type: SET_UP_CROSS_PRODS,
    payload,
});

export const setTempData = (payload: any[]) => ({
    type: SET_TEMPORARY_DATA,
    payload,
});

export const setCategories = (payload: any) => ({
    type: CATEGORIS_DATA,
    payload,
});

export const setFbPixel = (payload: any) => ({
    type: SET_FB_PIXEL,
    payload,
});

export const setPopup = (payload: boolean) => ({
    type: SET_POPUP,
    payload,
});
export const setPopupName = (payload: string) => ({
    type: SET_POPUP_NAME,
    payload,
});

export const setCasheVersion = (payload: any) => ({
    type: SET_CASH_VERSION,
    payload,
});

export const setSocial = (payload: any) => ({
    type: SET_SOCIAL,
    payload,
});

export const setStoreConfigs = (payload: any) => ({
    type: SET_STORE_CONFIGS,
    payload,
});

export const setCoreConfigs = (payload: any) => ({
    type: SET_CONFIGS,
    payload,
});